const data = [
    {id: 1, url: require("../images/slider-1.png")},
    {id: 2, url: require("../images/slider-2.png")},
    {id: 3, url: require("../images/slider-3.png")},
    {id: 4, url: require("../images/slider-4.png")},
];

export default function Slider() {
  return (
    <div className="absolute mt-[376px] flex justify-center w-full cols">
      <div>
        <div className="grid grid-cols-4 gap-[20px]">
            {
                data.map((item) => {
                    return <div key={item.id}>
                        <img
                            className="w-[270px] h-[304px]"
                            src={(item.url)}
                            alt="slider1"
                        />
                    </div>
                })
            }
        </div>
        <button className="w-[194px] h-[60px] mt-[40px] bg-[#FDBA10] rounded-[30px] font-black">
          CONTACT US
        </button>
      </div>
    </div>
  );
}
