export default function PartFour() {
  return (
    <div className="flex-1 w-[70%] bg-[#C98A40] h-50">
      <div className="text-[144px]/[138px] font-extrabold mt-[50px]">
        How we <br />
        work
      </div>
    </div>
  );
}
