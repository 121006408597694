import Social from "./social"

export default function PartOne() {
  return (
    <div className="flex-1 w-[70%] bg-[#C98A40] relative">
      <div className="pl-[352px] mt-[16px] flex gap-[16px] items-center">
        <img
          className="w-[53px] h-[52px]"
          src={require("../images/logo.png")}
          alt="logo"
        />
        <h1 className="font-bold text-[28px]/[33px]">
          Peachy Pup <br /> Bakery
        </h1>
      </div>
      <div className="pl-[352px] text-left mt-[195px]">
        <div className="font-extrabold text-[74px]/[89px] text-left">
          Tasty pastries
        </div>
        <div className="mt-[40px] font-semibold text-[18px]/[26px] max-w-[552px]">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the
        </div>
        <button className="w-[194px] h-[60px] mt-[40px] bg-[#FDBA10] rounded-[30px] font-black">
          SEE MORE
        </button>
      </div>
      <div className="flex absolute bottom-0 items-end">
        <div className="bg-white h-[201px] w-[449px] pr-[37px] pt-[30px]">
          <Social />
        </div>
        <div className="mb-[20px] ml-[20px] font-bold text-[18px]/[26px]">
          Telephone: <span className="font-normal">+7 700 000 00 00</span>
        </div>
      </div>
    </div>
  );
}
