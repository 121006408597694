import './App.css';
import SVGBG from './images/bg-1.svg';
import Slider from './components/slider';
import PartOne from './components/partone';
import PartTwo from './components/parttwo';
import PartThree from './components/partthree';
import PartFour from './components/partfour';

function App() {
  return (
    <div className="App">
      <div className='h-[1680px]'>
        
        <div className="flex h-2/4 relative">
          <PartOne />
          <PartTwo />

          <div className='absolute top-[253px] right-[366px]'>
            <div className='w-full'>
              <img className='w-full' src={SVGBG} alt='bg' />
            </div>
          </div>
        </div>
        <div className="flex h-2/4 relative">
          <PartThree />
          <PartFour />
          <Slider />
        </div>

      </div>
    </div>
  );
}

export default App;
