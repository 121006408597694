export default function PartTwo() {
  return (
    <div className="w-[30%] bg-white h-50">
      <div className="w-full h-[322px]">
        <img
          className="w-full h-[322px]"
          src={require("../images/bg-2.png")}
          alt="logo"
        />
      </div>
    </div>
  );
}
