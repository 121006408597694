import BXL from '../images/bxl.svg';
import FACEBOOK from '../images/facebook.svg'
import INSTAGRAM from '../images/instagram.svg'
import TWITTER from '../images/twitter.svg'

export default function Social() {
    return (
      <div className="flex gap-[10px] flex-col items-end">
        <a className='w-[24px] h-[24px]'>
            <img className='w-full' src={BXL} alt='bg' />
        </a>
        <a className='w-[24px] h-[24px]'>
            <img className='w-full' src={FACEBOOK} alt='bg' />
        </a>
        <a className='w-[24px] h-[24px]'>
            <img className='w-full' src={INSTAGRAM} alt='bg' />
        </a>
        <a className='w-[24px] h-[24px]'>
            <img className='w-full' src={TWITTER} alt='bg' />
        </a>
      </div>
    );
  }
  