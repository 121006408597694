export default function PartThree() {
  return (
    <div className="w-[30%] bg-white flex justify-end">
      <div className="max-w-[277px] mt-[170px] ">
        <div className="text-[#C98A40] text-[18px]/[26px] text-left mr-[20px]">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the
        </div>
      </div>
    </div>
  );
}
